import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { DecimalField, FormField, RadioListField } from 'shared/components/Formik';
import { resolveSalaryPostFix } from 'ContractV3/utils/salaries/resolveSalaryPostFix';
import { fieldNames } from 'ContractV3/constants/fieldNames';
import InputGroup from 'shared/components/InputGroup';
import InputPostfix from 'shared/components/InputPostfix';
import { HiddenField } from 'shared/components/Formik';
import { resolveArrayName } from 'shared/ReForm/utils/resolveArrayName';
import {
    getTargetWorkTimeGrouperSetting,
    getWorkTimeGroupingOptions,
    isMinWorkTimeSelectionRequired, labelProvider,
} from 'ContractV3/utils/salaries/workTimeGrouperSettings';
import { TYPE_COST_REIMBURSEMENT, SUB_TYPE_COST_REIMBURSEMENT_CUSTOM, salaryTypes } from 'shared/constants/salaryTypes';
import { Value } from 'shared/components/Value';
import { Divisors } from 'shared/ReForm/containers/CustomBlocks/ContractV3/Form/Salaries/Divisors';

const MinimumRequiredWorkTimeField = ({ arrayName, workTimeGroupingSettings }) => {
    // Jos asetuksia tai mahdollisia optiota vain 1 niin käytetään joko ainoaa optiota tai seurataan pääfieldin arvoa
    const radioListOptions = getWorkTimeGroupingOptions(getTargetWorkTimeGrouperSetting(workTimeGroupingSettings, 2, 'B'));
    const hasSingleOption = radioListOptions.length === 1;
    const isHiddenWorkTimeFieldUsed = hasSingleOption;
    const firstOptionValue = hasSingleOption ? radioListOptions[0].value : null;
    const minRequiredWorkTimeDescription = firstOptionValue
        ? labelProvider(Math.floor(firstOptionValue / 60), firstOptionValue % 60)
        : '';

    return (
        <FormField
            label={_trans('Kulukorvaukseen vaadittava hoitojakson vähimmäiskesto', [], 'holiday_days')}
            name={resolveArrayName(arrayName, fieldNames.MIN_REQUIRED_WORK_TIME_COST_REIMBURSEMENTS)}
        >
            {isHiddenWorkTimeFieldUsed && (
                <Fragment>
                    <strong>{minRequiredWorkTimeDescription}</strong>
                    <HiddenField
                        name={resolveArrayName(arrayName, fieldNames.MIN_REQUIRED_WORK_TIME_COST_REIMBURSEMENTS)}
                        value={firstOptionValue}
                    />
                </Fragment>
            )}
            {! isHiddenWorkTimeFieldUsed && (
                <RadioListField
                    options={radioListOptions}
                />)}
        </FormField>
    );
};

/**
 * Kulukorvauksen erityislisä, erikseen sovittu.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const CostReimbursementCustom = ({ name, label, arrayName }) => {
    // Palkkatyyppi
    const [field] = useField(name);
    const [salaryField] = useField(fieldNames.SALARY_TYPE);
    const salaryType = parseInt(salaryField.value, 10);
    const isNewContract = useSelector(select.contract.isNewContract);
    const isUnfinishedContract = useSelector(select.contract.isUnfinishedContract);
    const employees = useSelector(isNewContract
        ? select.employees.getEmployees
        : select.contract.getEmployees);
    const [employeeIdField] = useField(fieldNames.EMPLOYEE);
    const isLoadingEmployees = useSelector((state) => state.loading.effects.employees.fetchEmployees);
    const selectedEmployees = useMemo(() => employees.filter((employee) => employeeIdField.value.includes(employee.userId)), [employees, employeeIdField.value]);
    const [isTableSalaryInUseField] = useField(resolveArrayName(arrayName, fieldNames.IS_TABLE_SALARY_IN_USE));
    const isTableSalaryInUse = isTableSalaryInUseField?.value ?? true;
    const hasCostReimbursementTableSalaries = useSelector(select.contract.hasCostReimbursementTableSalaries);

    const [costReimbursementSpecialAllowanceField] = useField(resolveArrayName(arrayName, fieldNames.COST_REIMBURSEMENT_SPECIAL_ALLOWANCE));
    const [divisorField,,divisorHelpers] = useField(fieldNames.CONTRACT_GROUP_DIVISORS);
    const divisorValue = Array.isArray(divisorField.value) ? divisorField.value : [];

    /**
     * OPH: jos kellonaikapohjaiset kirjaukset käytössä ja halutaan käyttää vapaapalkkiota niin palkkiolle pitää valita
     * tieto minimityöajasta, joka palkkioon oikeuttaa. Pakotetaan valitsemaan käytössä olevista asetuksista.
     *
     * Jos hoitopalkkiolle ja kulukorvauksille on samat asetukset niin tämä valitaan hoitopalkkion yhteydessä
     */
    const workTimeGroupingSettings = useSelector(select.tableSalary.getWorkTimeGroupingSettings);
    const isMinimumWorkTimeRequiredFieldShown = isMinWorkTimeSelectionRequired(
        isTableSalaryInUse,
        workTimeGroupingSettings,
        salaryType,
        TYPE_COST_REIMBURSEMENT,
        SUB_TYPE_COST_REIMBURSEMENT_CUSTOM
    );

    const costReimbursementCustom = isNaN(field.value) || (field.value?.toString().trim() === '')
        ? 0
        : parseFloat(field.value);
    const costReimbursementSpecialAllowance = (
        isNaN(costReimbursementSpecialAllowanceField.value)
        || (costReimbursementSpecialAllowanceField.value?.toString().trim() === '')
    )
        ? 0
        : parseFloat(costReimbursementSpecialAllowanceField.value);

    const totalSalary = costReimbursementCustom + costReimbursementSpecialAllowance;
    const costDivisorFields = divisorValue.filter((divisor) => divisor.hasOwnProperty('costDivisor'));

    const showCustomInputFields = (! isTableSalaryInUse || ! hasCostReimbursementTableSalaries) && costDivisorFields.length > 1;

    return (
        <Fragment>
            <FormField name={name} label={label}>
                <InputGroup>
                    <DecimalField name={name} size={6} />
                    <InputPostfix postfix={resolveSalaryPostFix(salaryType === salaryTypes.PER_HOUR ? salaryTypes.PER_DAY : salaryType)} />
                </InputGroup>
            </FormField>
            {isMinimumWorkTimeRequiredFieldShown && <MinimumRequiredWorkTimeField
                arrayName={arrayName}
                workTimeGroupingSettings={workTimeGroupingSettings} />
            }
            <FormField label={_trans('Kulukorvauksen erityislisä yhteensä', {}, 'jobContract')} name={`${name}Total`}>
                <Value ariaDescribedBy={`${name}Total`}>
                    {resolveSalaryPostFix(salaryType === salaryTypes.PER_HOUR ? salaryTypes.PER_DAY : salaryType, _currency(totalSalary))}
                </Value>
            </FormField>
            {(! isLoadingEmployees && showCustomInputFields && totalSalary !== 0) && (
                <Divisors
                    divisorKey="costDivisor"
                    canChangeDivisors={isNewContract || isUnfinishedContract}
                    divisors={costDivisorFields}
                    divisorHelpers={divisorHelpers}
                    employees={selectedEmployees}
                    totalSalary={totalSalary}
                />
            )}
        </Fragment>
    );
};

CostReimbursementCustom.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    arrayName: PropTypes.string.isRequired,
};

MinimumRequiredWorkTimeField.propTypes = {
    arrayName: PropTypes.string.isRequired,
    workTimeGroupingSettings: PropTypes.array.isRequired,
};

export default CostReimbursementCustom;
