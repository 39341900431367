import React, { Fragment, useEffect, useRef } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { select } from '@rematch/select';
import PropTypes from 'prop-types';
import { useBooleanToggle } from '@mantine/hooks';
import { DropzoneUploader } from 'shared/containers/DropzoneUploader';
import { FormField, TextField, Form, ChoiceField } from 'shared/components/Formik';
import { ActionBar, Feedback, SubmitButton } from 'shared/components';
import FormikMaxTextArea from 'shared/components/Formik/FormikMaxTextArea';
import { alert } from 'shared/utils';
import { documentsCategoryTypes } from 'shared/DocumentsCategories/constants/documentsCategoryTypes';
import { DocumentExpireDateInput } from 'shared/DocumentsCategories/components/DocumentExpireDateInput';
import {
    FAMILY_CARE_START_COMPENSATION,
    FAMILY_CARE_ADDITIONAL_COMPENSATION,
    FAMILY_OR_RELATIVE_CARE_TRAVELLING_COMPENSATION,
    SUPPORTER_TRAVELLING_COMPENSATION,
    AUXILIARY_CUSTODY_TRAVELLING_COMPENSATION,
    COMPENSATION_EARNER_TRAVELLING_COMPENSATION_TYPES,
    SUPPORTER_COST_REIMBURSEMENT,
    AUXILIARY_CUSTODY_COST_REIMBURSEMENT,
    COMPENSATION_NON_EARNER_TRAVELLING_COMPENSATION_TYPES,
    COMPENSATION_EARNER_DOCUMENT_TYPES,
    FAMILY_OR_RELATIVE_CARE_TYPES, FAMILY_OR_RELATIVE_CARE_DOCUMENT_TYPES,
} from 'TimeRegistration/pay-period-data/PayPeriodDataType';
import { Value } from 'shared/components/Value';

const inputNameToLabelMap = {
    title: _trans('Dokumentin nimi', {}, 'documents'),
    category: _trans('Kategoria', {}, 'documents'),
    expireDate: _trans('Viimeinen voimassaolopäivä', 'common'),
    description: _trans('Kuvaus', {}, 'common'),
    fileName: _trans('Tiedostonimi', {}, 'common'),
};

const translatePayPeriodDataType = (type) => {
    switch (type) {
        case FAMILY_CARE_START_COMPENSATION: return _trans('Käynnistämiskorvaus', {}, 'documents');
        case FAMILY_CARE_ADDITIONAL_COMPENSATION: return _trans('Erilliskorvaus', {}, 'documents');
        case FAMILY_OR_RELATIVE_CARE_TRAVELLING_COMPENSATION: return _trans('Matkakustannusten korvaus', {}, 'documents');
        case SUPPORTER_TRAVELLING_COMPENSATION: return _trans('Tukihenkilön Matkakustannusten korvaus', {}, 'documents');
        case AUXILIARY_CUSTODY_TRAVELLING_COMPENSATION: return _trans('Oheishuoltajan matkakustannustenkorvaus', {}, 'documents');
        case SUPPORTER_COST_REIMBURSEMENT: return _trans('Tukihenkilön kulukorvaus', {}, 'extract');
        case AUXILIARY_CUSTODY_COST_REIMBURSEMENT: return _trans('Oheishuoltajan kulukorvaus (ei toimeksiantosopimusta)', {}, 'extract');
    }
};

export const DocumentsAttachmentForm = ({ isCompensationEarnerContract, categoryType, relatedEntityId, onSuccess }) => {
    const dispatch = useDispatch();
    const categories = useSelector(select.documents.getCategories);
    const dropzoneRef = useRef();
    const isEmployee = useSelector(select.userMetadata.isEmployee);
    const isEmployer = useSelector(select.userMetadata.isEmployer);
    const [isPending, setPending] = useBooleanToggle(false);

    useEffect(() => {
        if (categoryType === documentsCategoryTypes.JOB_CONTRACT) {
            dispatch.documents.fetchContractCategories(relatedEntityId);
        } else if (categoryType === documentsCategoryTypes.PAY_PERIOD) {
            dispatch.documents.fetchPayPeriodCategories(relatedEntityId);
        } else {
            dispatch.documents.fetchCategories([categoryType]);
        }
    }, [categoryType, dispatch.documents]);

    return (
        <Formik
            initialValues={{
                title: '',
                expireDate: '',
                description: '',
                category: '',
                payPeriodDataType: '',
            }}
            onSubmit={() => {
                setPending(true);
                dropzoneRef.current.processQueue();

            }}
            validationSchema={Yup.object().shape({
                title: Yup.string().required(_trans('Dokumentille on annettava aihe', {}, 'documents')),
                expireDate: Yup.string().when('category', {
                    is: (category) => ! categories.find((c) => c.uuid === category)?.isExpiryAfterContractTerminating,
                    then: Yup.string().required('Viimeinen voimassaolopäivä on annettava', {}, 'common'),
                }),
                description: Yup.string().max(200).required('Dokumentin kuvaus on annettava', {}, 'common'),
                category: Yup.string().required('Kategoria on valittava', {}, 'common'),

            })}
        >
            {({ values }) => {
                const category = categories.find((c) => c.uuid === values.category);
                return (
                    <Form>
                        <FormField
                            name="category" label={inputNameToLabelMap.category}
                            tooltip={_trans('Valitse kategoria perustuen dokumentin käyttötarkoitukseen', {}, 'documents')}
                        >
                            <ChoiceField options={categories.map((c) => ({
                                value: c.uuid,
                                label: c.name,
                            }))} />
                        </FormField>
                        <FormField name="title" label={inputNameToLabelMap.title}>
                            <TextField />
                        </FormField>
                        {category?.isExpiryAfterContractTerminating
                            ? (
                                <FormField label={inputNameToLabelMap.expireDate}
                                    isContentFormField={false}>
                                    <Value>{_trans('Sopimuksen päättymisen jälkeen %year% vuotta ja %month% kuukautta', {
                                        year: category.defaultExpiryYear,
                                        month: category.defaultExpiryMonth,
                                    }, 'documents')}</Value>
                                </FormField>
                            ) : (
                                <DocumentExpireDateInput
                                    label={inputNameToLabelMap.expireDate}
                                    isInputHidden={ isEmployee || isEmployer }
                                    categoryToExpiryMap={categories.reduce((map, category) => ({ ...map, [category.uuid]: category.defaultExpiryDate }), {})}
                                />
                            )}
                        <FormField name="description" label={inputNameToLabelMap.description}>
                            <FormikMaxTextArea
                                maxLength={200}
                            />
                        </FormField>
                        {category?.useSmartScan && (
                            <Fragment>
                                <FormField label={_trans('Valitse lisäkorvauslaji', {}, 'documents')} name="payPeriodDataType">
                                    <ChoiceField options={category.allowedPayPeriodDataTypes.filter((type) => {
                                        // Jos laji ei kuulu kumpaankaan, näytetään valinta
                                        if (! COMPENSATION_EARNER_DOCUMENT_TYPES.includes(type) && ! FAMILY_OR_RELATIVE_CARE_DOCUMENT_TYPES.includes(type)) {
                                            return true;
                                        }

                                        return isCompensationEarnerContract 
                                            ? COMPENSATION_EARNER_DOCUMENT_TYPES.includes(type)
                                            : FAMILY_OR_RELATIVE_CARE_DOCUMENT_TYPES.includes(type);

                                    }).map((type) => ({
                                        value: type.toString(),
                                        label: translatePayPeriodDataType(type),
                                    }))} />
                                </FormField>
                                <Feedback modifierClass="u-margin-bottom-small" type="info">
                                    {_trans('Tallennutusta dokumentista yritetään lukea kokonaissumma sekä päivämäärä, jotka esitäytetään lisäkorvauslomakkeelle.', {}, 'documents')}
                                </Feedback>
                            </Fragment>
                        )}
                        <DropzoneUploader
                            onSuccess={(resp) => {
                                dispatch.documents.fetchDocuments({ categoryType, relatedId: relatedEntityId });
                                setPending(false);
                                const json = JSON.parse(resp.xhr.response);
                                onSuccess(json.document, json.isSmartScan ? { ...json.smartScanResult, description: json.description } : null);
                            }}
                            onFailure={(file, message) => {
                                setPending(false);

                                // Jos xhr ei ole, niin ei olla aloitettu edes uploadausta, vaan virheellinen tiedosto
                                if (! file.xhr) {
                                    dropzoneRef.current.removeAllFiles();
                                    alert(<p>{message}</p>);

                                    return;
                                }
                                // Poista tiedosto ja lisää uudestaan jonoon => antaa mahdollisuuden korjailla.
                                dropzoneRef.current.removeAllFiles();
                                dropzoneRef.current.addFile(file);
                                const error = JSON.parse(file.xhr.response).error;
                                alert(
                                    <Fragment>
                                        <p>
                                            {_trans('Dokumentin tallentaminen ei onnistunut.', {}, 'documents')}
                                        </p>
                                        <ul className="u-margin-bottom-none">
                                            {error === 'error.maxsize' && <li>{_trans('Tiedostokoko on liian suuri. Maksimi 5MB', {}, 'documents')}</li>}
                                            {/* Muodostaa 'fieldName:errorMessage;fieldName2:errorMessage2' -> [[fieldName, errorMessage], ...] */}
                                            {error?.split(';').map((error) => error.split(':')).map(([fieldLabel, message], key) => (
                                                <li key={key}>
                                                    <b>{inputNameToLabelMap[fieldLabel]}</b>
                                                    <br/>{message}
                                                </li>
                                            ))}
                                        </ul>
                                    </Fragment>
                                );
                            }}
                            maxFiles={1}
                            uploadUrl="/_uploader/document/upload"
                            id="documents"
                            acceptedFiles="application/pdf,image/*"
                            customParameters={{
                                folder: `/documents/${categoryType}`,
                                relatedEntityId,
                                ...values,
                            }}
                            ref={dropzoneRef}
                            isAutoProcessQueue={false}

                        />
                        <ActionBar alignItems="right" modifierClass="u-margin-top-small u-padding-bottom-large@small">
                            <SubmitButton
                                isPending={isPending}
                                mdIcon="save"
                            >
                                {_trans('Tallenna', {}, 'common')}
                            </SubmitButton>
                        </ActionBar>
                    </Form>
                );
            }}
        </Formik>
    );
};

DocumentsAttachmentForm.propTypes = {
    isCompensationEarnerContract: PropTypes.bool,
    categoryType: PropTypes.string.isRequired,
    relatedEntityId: PropTypes.number.isRequired,
    onSuccess: PropTypes.func.isRequired,
};

DocumentsAttachmentForm.defaultProps = {
    isCompensationEarnerContract: false,
};
