import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import SignaturesEmployer from 'shared/ReForm/containers/CustomBlocks/ContractV3/Form/Signatures/Employer';
import { signatureTypes } from 'shared/constants/signatureTypes';

/**
 * HA-puolen allekirjoittaja.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const SignaturesCommuneOperator = ({ name, placeholder, attributes, label }) => {
    const isEmployer = useSelector(select.userMetadata.isEmployer);

    return (
        <SignaturesEmployer
            name={name}
            placeholder={placeholder}
            attributes={attributes}
            apiEndPoint="/commune/api/v1/commune/contract-signing-access"
            employerSignatureType={signatureTypes.TYPE_COMMUNE_USER_SPECIFIC}
            isEmployer={isEmployer}
            label={label}
        />
    );
};

SignaturesCommuneOperator.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    attributes: PropTypes.object,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

SignaturesCommuneOperator.defaultProps = {
    placeholder: _trans('Valitse hyvinvointialueen allekirjoittaja', {}, 'jobContract'),
    attributes: {},
    label: null,
};

export default SignaturesCommuneOperator;
