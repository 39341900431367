import _ from 'lodash';
import scrollToElement from 'scroll-to-element';
import variableTypes from 'shared/constants/collectiveAgreementVariableTypes';

/**
 * Vieritetään sivu ensimmäiseen löydettyyn virheelliseen elementtiin.
 * @param model
 * @param hasSimpleIdList bool
 */
function scrollToFirstError(model, hasSimpleIdList = false) {
    let allErrors = model;
    if (! hasSimpleIdList) {
        allErrors = getModelErrors(model);
    }
    allErrors = allErrors.map((error) => {
        // Jos löytyy .numero., niin muutetaan mutoon [numero]. jotta löytyy elementti domista
        if (! /\.\d\./gm.test(error)) {
            return error;
        }

        return error.replace(/\.(\d)\./gm, ($_,index) => `[${index}].`);
    });

    // Etsitään ensimmäinen virheellinen elementti joka on äärimmäisenä ruudun vasemmassa yläkulmassa.
    // Joo, paska state-of-the-art hack mutta toimii siihen asti että tähän tulee jokin
    // kunnollinen kerro-minulle-ensimmäinen-virheellinen-kenttä-modelin-mukaisesti
    const sortedErrors = _.sortBy(allErrors, [(fieldName) => {
        const fieldElement = getElement(fieldName);

        if (_.isElement(fieldElement)) {
            const coords = fieldElement.getBoundingClientRect();
            return coords.top + window.scrollY;
        } else {
            return 9999999999;
        }
    }]);

    const firstInvalidFieldName = _.head(sortedErrors);
    const firstInvalidElement = getElement(firstInvalidFieldName);

    // Jos löytyi, scrollataan siihen
    if (_.isElement(firstInvalidElement)) {
        // Fokusoidaan elementtiin automaattisesti jos se on input-tag tai sen rooli on button.
        const nodeName = firstInvalidElement.nodeName.toLowerCase();
        if (_.includes(['input', 'textarea', 'button', 'submit'], nodeName)
            || firstInvalidElement.getAttribute('role') === 'button') {
            firstInvalidElement.focus();
        }

        scrollToElementWithOffset(firstInvalidElement);
    }
}

/**
 * Scrollataan
 * @param element
 */
export const scrollToElementWithOffset = (element) => {
    const rootElement = document.getElementById('root');
    const rootElementTop = rootElement.offsetTop;

    scrollToElement(element, {
        offset: -(6 + rootElementTop),
        duration: 125,
    });
};

/**
 * Virheobjekti flätiksi stringiobjektiksi jotta saadaan vertailtavien elementtien nimet oikein.
 * Esim. dimension: { 212 } => dimension.212, salaries: [{ initialSalary...}] => salaries.0.initialSalary
 * @param errors
 * @param baseKey
 * @returns {{}}
 */
export const flattenErrorObject = (errors, baseKey = '') => (typeof errors === 'object' && errors !== null) && Object.entries(errors)
    .reduce((acc, [key, value]) => {
        if (value === null) return acc;

        if (typeof value === 'object' && ! Array.isArray(value)) {
            return Object.assign({}, acc, flattenErrorObject(value, baseKey + key + '.'));
        }

        if (Array.isArray(value)) {
            const sub = value.reduce((acc, item, index) => (
                Object.assign({}, acc, flattenErrorObject(item, baseKey + key + '.' + index + '.'))
            ), {});

            return Object.assign({}, acc, sub);
        }

        return Object.assign({}, acc, { [baseKey + key]: value });
    }, {});

export const scrollToError = (errors) => {
    let firstErrorCoord = Infinity;

    // Selvitetään koordinaateissa ylimmän (eli ensimmäisen) virheen kentän nimi
    const firstErrorName = Object.entries(flattenErrorObject(errors))
        .reduce((acc, [fieldName]) => {
            const fieldElement = getElement(fieldName);
            const errorTop = fieldElement
                ? fieldElement.getBoundingClientRect().top + window.scrollY
                : Infinity;

            if (errorTop < firstErrorCoord) {
                firstErrorCoord = errorTop;
                return fieldName;
            }

            return acc;
        }, '');

    const firstErrorElement = getElement(firstErrorName);

    // Jos löytyi, scrollataan siihen
    if (firstErrorElement) {
        // Fokusoidaan elementtiin automaattisesti jos se on select, input, textarea, button tai submit-elementti tai sen rooli on button.
        const nodeName = firstErrorElement.nodeName.toLowerCase();
        if (['select', 'input', 'textarea', 'button', 'submit'].includes(nodeName)
            || firstErrorElement.getAttribute('role') === 'button') {
            firstErrorElement.focus();
        } else {
            // Muuten yritetään etsiä sisältä fokusoitavaa.
            const inputElements = firstErrorElement.getElementsByTagName('input');
            if (inputElements.length) {
                inputElements[0].focus();
            }
        }

        const rootElement = document.getElementById('root');
        const rootElementTop = rootElement.offsetTop;

        // Scrollataan elementin kohdalle
        scrollToElement(firstErrorElement, {
            offset: -(6 + rootElementTop),
            duration: 125,
        });
    }
};

/**
 * Hae elementti DOMista joko nimellä tai id:llä.
 * @param elementName
 * @returns {*|Element}
 */
function getElement(elementName) {
    return document.getElementsByName(elementName)[0] || document.getElementById(elementName);
}

/**
 * Käydään läpi koko lomakemalli ja kerätään mahdolliset virheet. Tunnisteena kentän nimi.
 * Toimii ainoastaan React Redux Formin mallin kanssa.
 * @param model - malli
 * @returns {Array}
 */
function getModelErrors(model) {
    const allErrors = [];

    getErrors(model, allErrors);

    return allErrors;
}

function getErrors(model, ref) {
    const sectionNames = Object.keys(model);

    if (_.has(model, '$form.model')
        && sectionNames.length === 1
        && model.$form.valid === false) {
        console.log(model.$form.model);

        ref.push(model.$form.model);
    }

    _.map(sectionNames, (sectionName) => {
        const subModel = model[sectionName];

        if (_.has(subModel, 'model')) {
            const subModelName = model[sectionName].model;
            const isValid = _.get(subModel, 'valid', null);
            const fieldErrors = Object.keys(_.get(subModel, 'errors', []));
            if (!isValid && fieldErrors.length > 0) {
                ref.push(subModelName);
            }
        } else if (_.isObject(subModel)) {
            getErrors(subModel, ref);
        }
    });
}

/**
 * Nyppii Apista tulleesta vastauksesta virheviestit.
 * Esim:
 * children: {
 *   streetAddress: {errors: ["Tilapäistä HETU:a käyttävän työntekijän osoitetiedot on annettava."]},…}
 *   postCode: {errors: ["Tilapäistä HETU:a käyttävän työntekijän osoitetiedot on annettava."]}
 *   town: {errors: ["Tilapäistä HETU:a käyttävän työntekijän osoitetiedot on annettava."]}
 * }
 * @param response
 * @returns {Object} - palauttaa objektin jossa avain on kentän nimi ja arvo on virheviestit.
 */
const getApiErrors = (response) => _.mapValues(
    { ..._.get(response, 'data.children', {}), rootErrors: { errors: _.get(response, 'data.errors', []) } },
    (field) => _.get(field, 'errors', [])
);

// Jos on monimutkaisempi objekti kyseessä niin voidaan yrittää rekursiivisesti selvitellä siitä ongelmaa.
const getErrorMessageRecursively = (data) => {
    let errorMessage = null;
    const errors = data.errors ?? [];

    if (Array.isArray(errors) && errors.length > 0) {
        errorMessage = errors.join(' ');
        return errorMessage;
    }
    const children = data.children ?? null;

    if (!children || typeof children !== 'object') {
        return errorMessage;
    }

    for (const key in children) {
        errorMessage = getErrorMessageRecursively(children[key]);
        if (errorMessage) break;
    }
    return errorMessage;
};


/**
 * Parsii apin responsesta virheet. Palauttaa stringinä jotta esim notifikaatiot voi tätä hyödyntää.
 * Jos tarvii yksityiskohtaisempaa virheviestien näyttöä per kenttä käytä ylempää getApiErrors:ia.
 * @param response
 * @param isArrayResult
 * @returns {*}
 */
export const parseApiErrors = (response, isArrayResult = false) => {
    const apiErrors = Object.entries(response?.data?.children ?? {})
        .reduce((acc, [, errorObj]) => (
            acc.concat(errorObj?.errors ?? [])
        ), []);

    if (isArrayResult) {
        return apiErrors.length > 0
            ? apiErrors
            : [response.message || response.data?.errors || _trans('Tuntematon virhe', {}, 'common')];
    }

    return apiErrors.length > 0
        ? apiErrors.join(' ')
        : response.message || response.data?.errors || _trans('Tuntematon virhe', {}, 'common');
};

/**
 * Hakee TES:in valintojen oletusarvot. Arvoja palauttaa ainakin TesApi (getContractTesAction).
 * Palauttaa taulukon. Filtteröi pois valinnat joiden arvo on false (boolean-tyyppiset).
 *
 * @param variables - taulukko jossa collectiveAgreementVariableShape:n muotoisia objekteja.
 * @return array
 */
const getTESValues = (variables) => {
    const initialValues = [];
    _.map(variables, (variable) => {
        const name = _.get(variable, 'name');
        const variableType = _.get(variable, 'type');
        const defaultValue = _.get(variable, 'defaultValue');

        // Pähkitään tässä onko boolean sittenkin numeerinen arvo jonka käyttäjä voi syöttää
        const isCountable = !_.includes([
            'pay_holiday_compensation',
            //'pay_holiday_bonus',
            'pay_shortened_hours_compensation',
        ], name);
        const isBoolean = variableType === variableTypes.BOOLEAN;

        // Ei KELA-tukea eikä lomarahaa for now...
        const shouldAdd = [
            'kela_allowance',
            'pay_holiday_bonus',
        ].includes(name) === false;

        if (shouldAdd) {
            initialValues.push({
                ...variable,
                value: isBoolean && !isCountable ? (defaultValue === '1') : defaultValue,
                count: 0,
                type: isBoolean && isCountable ? 'number' : variableType,
            });
        }
    });

    return initialValues;
};

/**
 * Logiikka Formikin virheiden päättelyyn ja proppeihin.
 * @param meta
 * @returns {boolean}
 */
export const hasFormikErrors = (meta) => !!(meta.touched && meta.error);
export const getFormikErrorClassName = (meta) => hasFormikErrors(meta) ? 'has-error' : '';

export default {
    scrollToFirstError,
    getModelErrors,
    getApiErrors,
    getTESValues,
    getErrorMessageRecursively,
};
