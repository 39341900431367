import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { Dialog, Feedback, MDSpinner, TodoList } from 'shared/components';
import { translateTaxCardBasicType, validationTaxCardTypes } from 'shared/constants/taxCardTypes';
import _transMd from 'shared/utils/_transMd';

const CheckVeroTaxCardDialog = ({ isDialogOpen, closeDialog }) => {

    const isChecking = useSelector((state) => state.loading.effects.taxCards.checkVeroTaxCard);
    const contractTaxCardType = useSelector(select.taxCards.getContractTaxCardType);
    const checkVeroTaxCardResults = useSelector(select.taxCards.getCheckVeroTaxCardResults);
    const isCheckVeroTaxCardResultsSuccess = useSelector(select.taxCards.getIsCheckVeroTaxCardResultsSuccess);

    return (
        <Dialog
            title={_trans('Verolta saatavat verokortit', {}, 'tax_card')}
            canUnderlayClickExit={true}
            isOpen={isDialogOpen}
            onClose={() => closeDialog(false)}
            maxWidth="large"
        >
            {<div>
                {! isChecking && isDialogOpen && <div>
                    <p>{ _trans('Sopimukselle tarvitaan maksua varten ', {}, 'tax_card')} <b>{translateTaxCardBasicType(contractTaxCardType)}</b></p>
                    <p>{_trans('Veron rajapinnasta on saatavilla seuraavat verokortin tyypit', {}, 'tax_card')}</p>
                    <TodoList
                        list={
                            validationTaxCardTypes.map((type) => {
                                const checkTaxCard = checkVeroTaxCardResults?.find((checkTaxCard) => parseInt(checkTaxCard.IncomeType) === type);
                                const validFromText = _trans('Voimaantulopäivä: %start_date%', { start_date: _toLocaleDate(checkTaxCard?.ValidFrom) }, null, 'tax_card');
                                const taxCardName = translateTaxCardBasicType(type);

                                return (
                                    {
                                        task: <Fragment>{contractTaxCardType === type ? <b>{taxCardName}</b>: taxCardName} {checkTaxCard ? `(${validFromText})` : ''}</Fragment>,
                                        isDone: !! checkTaxCard,
                                    }
                                );
                            })
                        }
                    />
                    {isCheckVeroTaxCardResultsSuccess && <Feedback type="success" modifierClass="u-margin-bottom-small">{_transMd('Verokorttien haku onnistui.', {}, 'tax_card')}</Feedback>}
                </div>}
                {isChecking && <MDSpinner modifierClass="u-margin-top-small" wrap={false} />}
            </div>}
        </Dialog>
    );
};

CheckVeroTaxCardDialog.propTypes = {
    isDialogOpen: PropTypes.bool.isRequired,
    closeDialog: PropTypes.func.isRequired,
};

export default CheckVeroTaxCardDialog;
