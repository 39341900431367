import produce from 'immer';
import api from 'api';
import tesConstantKeys from 'TESBuilder/TESConstantMapper';
import { resolveCollectiveAgreementDefaultValues } from 'shared/utils/contractUtils';
import tesVariableKeys from 'shared/constants/tesVariableKeys';
import { ERROR_NOT_FOUND_FOR_DATE, TYPE_SALARY } from 'shared/constants/tableSalary';
import { createQueryParams } from 'shared/utils/commonUtils';
import { transformContractToBackend } from 'ContractV3/utils/transformers/transformContractToBackend';
import { contractDataTypes } from 'shared/constants/ContractV3/contractDataTypes';
import { contractTypes } from 'shared/constants/contractTypes';
import { contractStates } from 'shared/constants/ContractV3/contractStates';
import { isAssignmentContractType, isCompensationEarnerContractType, types } from 'shared/constants/contract';
import { resolveValueByType } from 'shared/ReForm/constants/resolveValueByType';
import { metadataNames } from 'ContractV3/constants/metadataNames';
import { signatureTypes } from 'shared/constants/signatureTypes';
import { appliesGeneralHolidayCalculation } from 'shared/constants/holidayPayMethods';
import templateStates from 'shared/constants/contractTemplateStates';
import { groupBy } from 'shared/utils/collectionHelpers';
import { tesTypes } from 'shared/constants/tesTypes';
import { templateTypes } from 'ContractV3/Builder/constants/templateTypes';

export const defaultContractTemplate = {
    type: templateTypes.ASSIGNMENT_CONTRACT,
    state: templateStates.STATE_DRAFT,
    category: null,
    replacedId: null,
};
const getPresetData = (state) => state.contractTemplate?.presetData ?? [];
const isNewContract = (state) => isNaN(parseInt(state.jobContractId, 10)) || isNaN(parseInt(state.jobContractDataId, 10));
const isCloningContract = (state) => state.isCloningContract ?? false;
const isAcceptedContract = (state) => state.jobContractState === contractStates.ACCEPTED;
const isDraftContract = (state) => [contractDataTypes.DRAFT, contractDataTypes.UNFINISHED].includes(state.jobContractDataType);
const isTerminatedContract = (state) => [contractStates.TERMINATED, contractStates.DISSOLVED].includes(state.jobContractState);
const isFixedTermContract = (state) => state.contractType === contractTypes.FIXED_TERM;
const isOpenEndedContract = (state) => state.contractType === contractTypes.OPEN_ENDED;
const isEmployerProposalContract = (state) => [contractStates.EMPLOYER_PROPOSAL].includes(state.jobContractState);
const isCommuneContract = (state) => state.isCommuneContract ?? false;
const isEmployeeNotUsingOima = (state) => state.metadataValues.hasOwnProperty(metadataNames.IS_EMPLOYEE_NOT_USING_OIMA)
    ? state.metadataValues[metadataNames.IS_EMPLOYEE_NOT_USING_OIMA]
    : [];
const getCollectiveAgreement = (state) => state.collectiveAgreement;
const getCollectiveAgreementId = (state) => parseInt(getCollectiveAgreement(state)?.collectiveAgreementId, 10);
const getCollectiveAgreementConstantByKey = (state, key) => state.collectiveAgreementConstantsDetails[key] ?? null;
const getCollectiveAgreementVariables = (state, contractCategory) => {
    if (! contractCategory) return getCollectiveAgreement(state)?.variables;

    return Object.entries(getCollectiveAgreement(state)?.variables ?? {}).reduce((acc, [name, variable]) => (
        variable.variable?.contractCategory === contractCategory
            ? Object.assign({}, acc, { [name]: variable })
            : acc
    ), {});
};
const getCollectiveAgreementBaseType = (state) => {
    const collectiveAgreement = getCollectiveAgreement(state);
    return collectiveAgreement?.type === tesTypes.CUSTOM
        ? collectiveAgreement?.baseType ?? null
        : collectiveAgreement?.type ?? null;
};
export const isReSignatureNeeded = (state) => state.metadataValues.hasOwnProperty(metadataNames.IS_RE_SIGNATURE_NEEDED)
    ? state.metadataValues[metadataNames.IS_RE_SIGNATURE_NEEDED]
    : false;

const resolveSignatures = (signatures) => {
    // TT:n mukaan groupit jotta mukaan tulee oikein myös other_party_signaturet per TT
    const groupedSignatures = groupBy(
        signatures.filter((signature) => signature?.type !== signatureTypes.TYPE_EMPLOYER_SPECIFIC),
        (signature) => signature.signerDetails?.employeeUserId);

    return Object.entries(groupedSignatures).reduce((acc, [employeeUserId, signatures]) => {
        // Etsitään TT:n allekirjoitus (pitäis olla vain yksi tai sitten on maailma mallillaan)
        const employeeSignature = signatures
            .find((signature) => signature.signerId?.toString() === employeeUserId?.toString());

        return acc.concat(employeeSignature);
    }, []);
};

const defaultState = {
    isVersionActive: false,
    collectiveAgreementConstantsDetails: {},
    collectiveAgreement: {
        name: '',
        type: null,
        baseType: null,
        restrictions: {},
        variables: {},
        hasTableSalaries: false,
        collectiveAgreementId: null,
    },

    salary: {
        isTableSalaryInUse: false,
        tableSalaryDate: null,
    },
    divisors: [],
    dimensions: [],
    signatures: [],
    previousSignatures: [],
    metadataValues: {},

    authorDetails: {},

    contractTemplate: defaultContractTemplate,
    contractAttributes: {},
    contractMetadata: {},

    isEditable: true,
    isRemovable: false,
    isDataRemovable: false,
    isCommuneContract: false,
    isChangingSalary: false,
    rejectData: {},

    isBuildMode: false,
    isPreviewMode: false,
    isSummaryView: false,
    isCloningContract: false,
    isDemoMode: false,

    linkedContracts: [],

    initialPartySsn: '',
};

/**
 * Yksittäiseen sopimukseen liittyviä asioita, joita haetaan esimerkiski sopimuksen id:lltä
 * contracts-modelli vastaa taas listoista
 */
export const contract = {
    state: defaultState,
    reducers: {
        /**
         * Soppari.
         */
        clearContractData: () => defaultState,
        setContractMetadata : (state, contractMetadata) => ({ ...state, contractMetadata }),
        setOriginalStartDate: (state, originalStartDate) => ({ ...state, originalStartDate }),
        setDataEndDate: (state, dataEndDate) => ({ ...state, dataEndDate }),
        setContractBaseData: (state, data) => Object.assign({}, state, data),
        setEmployer: (state, employerDetails) => ({ ...state, employerDetails }),
        setBeneficiary: (state, beneficiaryDetails) => ({ ...state, beneficiaryDetails }),
        setBenefitDecisionId: (state, benefitDecisionId) => ({ ...state, benefitDecisionId }),
        setIsChangingSalary: (state, isChangingSalary) => ({ ...state, isChangingSalary }),
        setContractState: (state, jobContractState) => ({ ...state, jobContractState }),
        setContractDataType: (state, jobContractDataType) => ({ ...state, jobContractDataType }),
        setContractType: (state, type) => ({ ...state, type }),
        setIsCloningContract: (state, isCloningContract) => ({ ...state, isCloningContract }),
        setIsDemoMode: (state, isDemoMode) => ({ ...state, isDemoMode }),
        setDivisors: (state, divisors) => ({ ... state, divisors }),
        setLinkedContracts: (state, linkedContracts) => ({ ... state, linkedContracts }),

        /**
         * Osapuolet.
         */
        setEmployees: (state, employeeDetails) => ({ ...state, employeeDetails }),
        setCustomer: (state, caredDetails) => ({ ...state, caredDetails }),
        resetIsEmployeeNotUsingOima: (state) => produce(state, (draftState) => {
            if (state.metadataValues.hasOwnProperty(metadataNames.IS_EMPLOYEE_NOT_USING_OIMA)) {
                draftState.metadataValues[metadataNames.IS_EMPLOYEE_NOT_USING_OIMA] = [];
            }
        }),
        setInitialPartySsn: (state, initialPartySsn) => ({ ...state, initialPartySsn }),
        resetInitialPartySsn: (state) => ({ ...state, initialPartySsn: '' }),

        /**
         * Voimassaolo.
         */
        setContractStartDate: (state, startDate) => ({ ...state, startDate }),

        /**
         * Päättäminen.
         */
        clearTerminationTime: (state) => ({ ...state, terminationTime: null }),

        /**
         * Työehtosopimus.
         */
        setCollectiveAgreementId: (state, collectiveAgreementId) => produce(state, (draftState) => {
            draftState.collectiveAgreement.collectiveAgreementId = collectiveAgreementId;
        }),
        setCollectiveAgreementConstantDetails: (state, { key, value }) => produce(state, (draftState) => {
            draftState.collectiveAgreementConstantsDetails[key] = value;
        }),
        // TES:n nimi
        setCollectiveAgreementName: (state, name) => produce(state, (draftState) => {
            draftState.collectiveAgreement.name = name;
        }),
        setCollectiveAgreementType: (state, type) => produce(state, (draftState) => {
            draftState.collectiveAgreement.type = type;
        }),
        setCollectiveAgreementBaseType: (state, baseType) => produce(state, (draftState) => {
            draftState.collectiveAgreement.baseType = baseType;
        }),
        setCollectiveAgreementJobContractType: (state, jobContractType) => produce(state, (draftState) => {
            draftState.collectiveAgreement.jobContractType = jobContractType;
        }),
        // Asetetaan rajoitteet
        setCollectiveAgreementRestrictions: (state, restrictions) => produce(state, (draftState) => {
            draftState.collectiveAgreement.restrictions = restrictions;
        }),
        // Asetetaan oletusarvot TES-muuttujille
        setCollectiveAgreementVariables: (state, collectiveAgreementVariables) => produce(state, (draftState) => {
            draftState.collectiveAgreement.variables = collectiveAgreementVariables;
        }),
        // Asetetaan tarvittavat muut muuttujat, esim. onko taulukkopalkat käytössä jne.
        // Harkita lisäilläkkö näitä edelleen käsin vaan tunkea silmät kiinni kaikki storeen.
        setCollectiveAgreementAttributes: (state, collectiveAgreement) => produce(state, (draftState) => {
            draftState.collectiveAgreement.workExpMasterTesId = collectiveAgreement?.workExpMasterTesId ?? null;
            draftState.collectiveAgreement.hasTableSalaries = collectiveAgreement?.hasTableSalaries;
            draftState.collectiveAgreement.hasCostReimbursementTableSalaries = collectiveAgreement?.hasCostReimbursementTableSalaries;
            draftState.collectiveAgreement.hasCostReimbursementSpecialAllowanceTableSalaries = collectiveAgreement?.hasCostReimbursementSpecialAllowanceTableSalaries;
            draftState.collectiveAgreement.collectiveAgreementId = parseInt(collectiveAgreement?.collectiveAgreementId, 10);
        }),

        /**
         * Salaari ja taulukkopalkat.
         */
        setHasTableSalaryInUse: (state, isTableSalaryInUse) => produce(state, (draftState) => {
            draftState.salary.isTableSalaryInUse = isTableSalaryInUse;
        }),
        setTableSalaryDate: (state, tableSalaryDate) => produce(state, (draftState) => {
            draftState.salary.tableSalaryDate = tableSalaryDate;
        }),

        /**
         * Dimensiot.
         */
        setDimensions: (state, dimensions) => ({ ...state, dimensions }),

        /**
         * Sopimuspohja.
         */
        setTemplate: (state, contractTemplate) => ({ ...state, contractTemplate }),
        clearTemplate: (state) => ({ ...state, contractTemplate: {} }),
        setTemplateName: (state, name) => produce(state, (draftState) => {
            draftState.contractTemplate.name = name;
        }),
        setTemplateState: (state, templateState) => produce(state, (draftState) => {
            draftState.contractTemplate.state = templateState;
        }),
        setTemplateReplacedId: (state, templateReplacedId) => produce(state, (draftState) => {
            draftState.contractTemplate.replacedId = templateReplacedId;
        }),
        setTemplateCategory: (state, templateCategory) => produce(state, (draftState) => {
            draftState.contractTemplate.category = templateCategory;
        }),
        setPresetData: (state, presetData) => produce(state, (draftState) => {
            draftState.contractTemplate.presetData = presetData;
        }),

        /**
         * Asettaa buildimoden. Tällä tutkitaan ollaanko formilla vai builderissa.
         */
        setIsBuildMode: (state, isBuildMode) => ({ ...state, isBuildMode }),

        /**
         * Asettaa esikatselutilaan. Vaikuttaa mm. siihen miten liite-komponentti näkyy.
         */
        setIsPreviewMode: (state, isPreviewMode) => ({ ...state, isPreviewMode }),

        /**
         * Ollaanko yhteenvetonäkymässä.
         */
        setIsSummaryView: (state, isSummaryView) => ({ ...state, isSummaryView }),

        /**
         * Allekirjoitukset.
         */
        setSignatures: (state, signatures) => produce(state, (draftState) => {
            draftState.signatures = signatures;
        }),
    },
    selectors: {
        /**
         * Soppari.
         */
        getContractData: (state) => state,
        getContractId: (state) => state.jobContractId,
        getContractType: (state) => state.type,
        getContractDataId: (state) => state.jobContractDataId,
        getBenefitDecisionId: (state) => state.benefitDecisionId,
        isChangingSalary: (state) => state.isChangingSalary,
        // Henkkaripuoli: näytetäänkö palkkioon liittyviä palikoita
        // OPH-puolella näiden näkyvyys tarkistetaan taabelisalaariswitchin kautta
        hasSalaryRelatedComponentsVisible: (state) => isCommuneContract(state)
            ? (isAcceptedContract(state) && state?.isChangingSalary) || isDraftContract(state)
            : true,
        isBeneficiaryCommuneControlled: (state) => state.beneficiaryDetails?.isCommuneControlled ?? false,
        isBeneficiarySelfEmployed: (state) => state.beneficiaryDetails?.isSelfEmployed ?? false,
        isBeneficiaryIdentified: (state) => state.beneficiaryDetails?.isUserIdentified ?? false,
        isEmployerIdentified: (state) => state.employerDetails?.isUserIdentified ?? false,
        getContractState: (state) => state.jobContractState,
        getContractDataType: (state) => state.jobContractDataType,
        getActiveContractDataId: (state) => state.activeContractDataId,
        getJobContractGroupId: (state) => state.jobContractGroupId,
        isJobContractGroup: (state) => state.jobContractGroupId !== null,
        getType: (state) => state.type,
        // Uusi soppari kyseessä jos id:itä ei löydy.
        isNewContract,
        isCloningContract,
        isDemoMode: (state) => state.isDemoMode ?? false,
        isAcceptedContract,
        isProposalContract: (state) => state.jobContractDataType === contractDataTypes.PROPOSAL,
        isDraftContract,
        isUnfinishedContract: (state) => [contractDataTypes.UNFINISHED].includes(state.jobContractDataType),
        isFixedTermContract,
        isOpenEndedContract,
        isEmployerProposalContract,
        isDocumentToolEnabled: (state) => state.isDocumentToolEnabled || false,
        isReportingFormsEnabled: (state) => state.isReportingFormsEnabled || false,
        isContractLinkingEnabled: (state) => state.isContractLinkingEnabled || false,
        canToggleLinking: (state) => state.canToggleLinking || false,
        isContractLinkingForceDisabled: (state) => state.isContractLinkingForceDisabled || false,
        isAttentionRequiredContract: (state) => state.isAttentionRequiredContract || false,
        isTerminatedContract,
        getTerminationTime: (state) => state.terminationTime,
        getRejectData: (state) => state.rejectData,
        getDivisors:(state) => state.divisors,
        isEmployeeRelatedWithoutHolidayGeneration:(state) => state.employeeRelated && !state.enforceHolidayGeneration,

        /**
         * Soppariin liittyvät attribuutit.
         */
        hasSkippedHolidayCompensations:(state) => state.contractAttributes?.hasSkippedHolidayCompensations,

        // Voiko päättämisen perua
        canRevertTermination: (state) => (
            (isOpenEndedContract(state) || isFixedTermContract(state)) && isTerminatedContract(state)
        ),

        /**
         * Voimassaolo.
         */
        getDataStartDate: (state) => state.dataStartDate,
        getDataEndDate: (state) => state.dataEndDate,
        getOriginalStartDate: (state) => state.originalStartDate,
        getStartDate: (state) => state.startDate,
        resolveStartDate: ({ originalStartDate, startDate }) => originalStartDate ?? startDate,
        // Onko soppari aktiivinen eli dataStartDate nykyhetkessä tai menneisyydessä.
        isVersionActive: (state) => state.isVersionActive ?? true,
        getNoticePeriodDisclaimerText: (state) => state.noticePeriodDisclaimerText ?? '',

        /**
         * Osapuolet.
         */
        getEmployer: (state) => state.employerDetails,
        getEmployees: (state) => state.employeeDetails,
        getEmployeeById: (state, userId) => {
            const employeeUserId = parseInt(userId, 10);
            return (state?.employeeDetails ?? []).find(({ userId }) => userId === employeeUserId);
        },
        getFirstEmployee: (state) => Array.isArray(state.employeeDetails) && state.employeeDetails.length > 0
            ? state.employeeDetails[0]
            : null,
        getCustomer: (state) => state.caredDetails,
        getBeneficiary: (state) => state.beneficiaryDetails,
        canResolveBeneficiaryData: (state) => state?.canResolveBeneficiaryData ?? false,
        getInitialPartySsn: (state) => state?.initialPartySsn ?? '',

        /**
         * Sopimuksen luoja / muokkaaja.
         */
        getAuthorDetails: (state) => state.authorDetails ?? {},

        /**
         * Tehtävä työ.
         */
        getJobTitle: (state) => state.jobTitle,
        getJobTitleId: (state) => state.jobTitle?.id,
        getJobTitleName: (state) => state.jobTitle?.name,

        /**
         * Allekirjoitukset.
         */
        getSignatures: (state) => state.signatures,
        getEmployeeSignatures: (state) => resolveSignatures(state.signatures),
        // Onko TA allekirjoittanut sopimuksen.
        hasEmployerSigned: (state) => state.signatures
            .find((signature) => signature?.type === signatureTypes.TYPE_EMPLOYER_SPECIFIC && signature?.isSigned),
        hasCommuneUserSigned: (state) => state.signatures
            .find((signature) => signature?.type === signatureTypes.TYPE_COMMUNE_USER_SPECIFIC && signature?.isSigned),
        // Onko TT allekirjoittanut sopimuksen.
        hasEmployeeSigned: (state, userId) => {
            const signerId = parseInt(userId, 10);
            const signature = state.signatures
                .find((signature) => signature?.type === signatureTypes.TYPE_USER_SPECIFIC && signature?.isSigned && signature?.signerId === signerId);

            return signature?.isSigned;
        },
        isFullySigned: (state) => (state?.isFullySigned && isReSignatureNeeded(state)) && state?.isVersionActive,

        // Käyttääkö TT Oima-palvelua
        isEmployeeNotUsingOima,

        // Onko tälle sopimusversiolle vaadittu allekirjoitukset uudelleen.
        isReSignatureNeeded,

        hasCommuneSigner: (state) => state.signatures.find((signature) => signature?.type === signatureTypes.TYPE_COMMUNE_USER_SPECIFIC),

        hasPreviousSignatures: (state) => (state?.previousSignatures ?? []).length > 0,

        // Etsii mahdollisen aiemman "TA":n allekirjoituksen.
        getPreviousEmployerSignature: (state, employerSignatureType = signatureTypes.TYPE_EMPLOYER_SPECIFIC) => (state?.previousSignatures ?? [])
            .find((signature) => [employerSignatureType].includes(signature?.type)),

        getPreviousCommuneOperatorSignature: (state) => (state?.previousSignatures ?? [])
            .find((signature) => [signatureTypes.TYPE_COMMUNE_USER_SPECIFIC].includes(signature?.type)),

        // Etsii mahdolliset aiemmat TT:n allekirjoitukset. Sisältää myös toisen osapuolen allekirjoituksen mikäli
        // TT:llä ei ole pääsyä Oimaan.
        getPreviousEmployeeSignatures: (state) => resolveSignatures(state.previousSignatures),

        /**
         * Dimensiot.
         */
        getDimensionTypes: (state) => state.dimensions ?? [],

        getDimensionTypeById: (state, dimensionTypeId) => {
            const id = parseInt(dimensionTypeId, 10);
            return state.dimensions.find((dimension) => dimension.dimensionType.id === id);
        },
        getDimensionById: (state, dimensionTypeId) => {
            const id = parseInt(dimensionTypeId, 10);
            return state.dimensions.find((dimension) => dimension.id === id);
        },

        /**
         * Työehtosopimus.
         */
        getCollectiveAgreement,
        getCollectiveAgreementId,
        getCollectiveAgreementConstantByKey,
        getHolidayEarningConversionFactor: (state) => {
            const earningSettings = getCollectiveAgreementConstantByKey(
                state,
                tesConstantKeys.HOLIDAY_DAY_EARNING_SETTINGS
            ) ?? [];
            return earningSettings.find((setting) =>
                setting.holidayDayConversionFactor !== undefined
                && typeof setting.holidayDayConversionFactor === 'string'
            )?.holidayDayConversionFactor ?? '1';
        },
        // Restrictions
        getCollectiveAgreementRestrictions: (state) => getCollectiveAgreement(state)?.restrictions,
        getCollectiveAgreementRestrictionsByKey: (state, restrictionKey) => getCollectiveAgreement(state)?.restrictions[restrictionKey] ?? [],
        // Variables. Sallii filtteröinnin contracCategoryn perusteella.
        getCollectiveAgreementVariables,
        getCollectiveAgreementVariableByKey: (state, variableKey) => state.collectiveAgreement.variables[variableKey] ?? {},
        getCollectiveAgreementBaseType,
        isHetaCollectiveAgreement: (state) => getCollectiveAgreementBaseType(state) === tesTypes.COMMUNE_HETA,
        /**
         * Alue. Sijaintitietoa vaaditaan tiettyjen työehtosopimusten lisien hinnan määrittelyyn
         */
        getArea: (state) => getCollectiveAgreementConstantByKey(state, tesVariableKeys.AREA),

        getLinkedContracts: (state) => state.linkedContracts ?? [],

        /**
         * Salaari, taulukkopalkat, takuupalkka ym.
         */
        getSalaryType: (state) => state.salaryType,
        hasTableSalaries: (state) => state.collectiveAgreement.hasTableSalaries ?? false,
        hasCostReimbursementTableSalaries: (state) => state.collectiveAgreement?.hasCostReimbursementTableSalaries ?? false,
        hasCostReimbursementSpecialAllowanceTableSalaries: (state) => state.collectiveAgreement?.hasCostReimbursementSpecialAllowanceTableSalaries ?? false,
        getHolidayExpirationSettings: (state) => getCollectiveAgreementConstantByKey(state, tesConstantKeys.HOLIDAY_DAY_EXPIRATION_SETTINGS),
        isTableSalaryInUse: (state) => state.salary.isTableSalaryInUse ?? false,
        isInitialSalaryResolved: (state) => state.isInitialSalaryResolved ?? false,
        isLaterThanInitialSalaryResolvable: (state) => state.isLaterThanInitialSalaryResolvable ?? false,

        getTableSalaryDate: (state) => state.salary.tableSalaryDate,

        /**
         * Muuta sovittua.
         */
        getMiscMutualAgreement: (state) => state.miscMutualAgreement,

        /**
         * Pohjat.
         */
        getTemplate: (state) => state.contractTemplate,
        getTemplateId: (state) => state.contractTemplate.id,
        getTemplateName: (state) => _transObj(state.contractTemplate?.name ?? {}),
        getTemplateState: (state) => state.contractTemplate?.state,
        getTemplateReplacedId: (state) => state.contractTemplate?.replacedId,
        getTemplateCategory: (state) => state.contractTemplate?.category ?? null,

        getPresetData,
        getPresetValueByKey: ({ contractTemplate }, key) => (
            contractTemplate.presetData
                ? contractTemplate.presetData[key]
                : null
        ),
        // Onko työsopimus
        isJobContract: (state) => state.type === types.JOB_CONTRACT,
        // Onko toimeksiantosopimus.
        isAssignmentContract: (state) => isAssignmentContractType(state.type),
        // Onko palkkiosopimus
        isCompensationEarnerContract: (state) => isCompensationEarnerContractType(getPresetData(state)?.type),

        // Onko sopimuksella aiempi hyväksymätön- tai luonnostilassa oleva versio
        isEditable: (state) => state.isEditable ?? false,

        // Voidaanko sopimus poistaa.
        isRemovable: (state) => state.isRemovable ?? false,
        isDataRemovable: (state) => state.isDataRemovable ?? false,

        // Onko kunnan sopimus.
        isCommuneContract,

        // Ollaanko rakentelumoodissa.
        isBuildMode: (state) => state.isBuildMode,
        isPreviewMode: (state) => state.isPreviewMode,
        isEditMode: (state) => ! state.isSummaryView,
        isSummaryView: (state) => state.isSummaryView,

        /**
         * Lomat
         */
        getHolidayPayMethod: (state) => state.holidayPayMethod,
        appliesGeneralHolidayCalculation: (state) => appliesGeneralHolidayCalculation(state.holidayPayMethod),

        /**
         * Metadataa
         */
        hasPendingSalaryUpdate: (state) => state.contractMetadata?.hasPendingSalaryUpdate,
        hasWorkExperienceLevels: (state) => state.contractMetadata?.hasWorkExperienceLevels,
        isHolidayDayDataImportable: (state) => state.contractMetadata?.isHolidayDayDataImportable,
        getJobDescriptionTypes: (state) => state.metadataValues?.jobDescriptionType ?? [],
    },
    effects: (dispatch) => ({
        /**
         * TODO: tämä pitää säätää jotenki järkevästi KUNTAPUOLELLE ku defaulttiarvo false. Tosin bäkkäri
         * ei just nyt muutenkaan välitä tosta jos ei assignmenttisopimus.
         */
        async fetchContract({ jobContract, jobContractData, isCloningContract, resolveContractInitialSalary = false }) {
            try {
                // Töötätään perään vain jos true, oletuksena näytetään senhetkinen salaari
                const urlParameters = resolveContractInitialSalary ? `&resolveContractInitialSalary=1` : '';
                const response = await api.get(`/api/v3/contracts/${jobContract}?jobContractData=${jobContractData}${urlParameters}`);
                if (response.status === 'ok') {
                    const data = response.data ?? {};

                    // Jos kloonataan sopparia ei tungeta mitään muuta storeen kuin templa.
                    if (isCloningContract) {
                        this.clearContractData(); // Varmuuden vuoksi tyhjäksi
                        this.setEmployees(data?.employeeDetails ?? []);
                        this.setSignatures(data?.signatures ?? []);
                        this.setCustomer(data?.caredDetails ?? {});
                        this.setTemplate(data.contractTemplate);
                        //this.setTemplate(data.contractTemplate.map(({ name, ...rest }) => Object.assign({}, rest, { name: _transObj(name) })));
                        this.resetIsEmployeeNotUsingOima();
                    } else {
                        this.setContractData(data);
                        this.setContractMetadata(response?.meta ?? {});
                    }
                    this.setIsCloningContract(isCloningContract);
                    return data;
                }
            } catch (error) {
                console.log(error);
                // Sopimusta ei löydy (tai ei ole proposal TT:lle)
                return null;
            }
        },

        /**
         * Päivittää sopimuksen dimensiot.
         * @param contractId
         * @returns {Promise<void>}
         */
        async fetchContractDimensions(contractId) {
            try {
                // Työajan dimensiot
                const response = await api.get(`/api/v2/contracts/${contractId}/dimensions`);
                this.setDimensions(response.data);
            } catch (e) {
                dispatch.notifications.addError(_trans('Sopimuksen dimensioita ei voitu päivittää.', {}, 'jobContract'));
                console.error(e);
            }
        },

        setContractData(data) {
            // Pitää vielä ehkä vähän pohtia tätä. Ei haluaisi storesta taas viidakkoa josta ei löydä mitään.
            const { collectiveAgreement, collectiveAgreementVariables, originalStartDate, ...rest } = data;
            this.setContractBaseData(rest);

            // Bäkkäri ei välttämättä palauta originalStartDatea. Aiheuttaa sen että UI:lla näkyy vanha arvo
            // kunnes sivun päivittää.
            // TODO: Varmaan parempi tapa korvata koko soppariroska kertarysäyksellä?
            this.setOriginalStartDate(originalStartDate);

            // Koska bäkkäri välttämättä palauta dataEndDatea jää tämä päivittämättä ja versioiden välillä
            // hypellessä jää viimeisin requestissa tullut dataEndDate voimaan.
            this.setDataEndDate(rest?.dataEndDate);

            this.setTableSalaryDate(rest.tableSalaryDate);
            this.setCollectiveAgreementBaseType(collectiveAgreement?.baseType);
            this.setCollectiveAgreementJobContractType(collectiveAgreement?.jobContractType);
            this.setCollectiveAgreementName(collectiveAgreement?.tesName);
            // Väännetään taulukko
            const variables = (collectiveAgreementVariables ?? []).reduce((acc, cur) => {
                const { name } = cur;
                const value = resolveValueByType(cur);
                return Object.assign({}, acc, { [name]: { value, variable: cur } });
            }, {});
            this.setCollectiveAgreementVariables(variables);
            this.setCollectiveAgreementAttributes(collectiveAgreement);
        },

        /**
         * Sopimusehdotuksen hyväksyminen.
         * @param jobContractData
         * @returns {Promise<void>}
         */
        async postProposal(jobContractData) {
            try {
                const response = await api.post(`/api/v3/contracts/${jobContractData}/propose`);
                if (response.status === 'ok') {
                    const jobContractDataType = parseInt(response.data?.jobContractDataType, 10);
                    // Jos oikeanlainen tila löytyy => päivitetään soppari
                    if (Object.values(contractDataTypes).includes(jobContractDataType)) {
                        this.setContractDataType(jobContractDataType);
                    }
                    // dispatch.notifications.addSuccess('Sopimus hyväksytty.', {}, 'jobContract');
                }
                return response;
            } catch (e) {
                dispatch.notifications.addError(_trans('Sopimusta ei voitu vahvistaa.', {}, 'jobContract'));
                console.log(e);
            }
        },

        /**
         * Sopimuksen allekirjoittaminen.
         * @param contractDataId
         * @param isSigningOnBehalf -
         *  false: TA osapuoli tai sähköinen TT
         *  true:  Hyvinvointialue allekirjoittaa TT:n tai sijais-TA:n puolesta (paperilla)
         * @param signatureType
         * @returns {Promise<void>}
         */
        async postSignature({ contractDataId, isSigningOnBehalf = false, signatureType = signatureTypes.TYPE_USER_SPECIFIC }) {
            try {
                const response = await api.post(isSigningOnBehalf
                    ? `/api/v3/contracts/${contractDataId}/sign-behalf/${signatureType}`
                    : `/api/v3/contracts/${contractDataId}/sign`
                );
                if (response.status === 'ok') {
                    const data = response.data ?? {};
                    this.setContractState(data.jobContractState);
                    this.setContractDataType(data.jobContractDataType);
                    const isFullySigned = (data?.signatures ?? []).every((signature) => signature?.isSigned);
                    if (isFullySigned) {
                        dispatch.notifications.addSuccess(_trans('Sopimus on allekirjoitettu ja voimassa.', {}, 'jobContract'));
                    }

                }
                return response;
            } catch (e) {
                console.log(e);
            }
        },

        /**
         * Hyväksyy sopimuksen suoraan voimaan (jos valittu ettei allekirjoituksia vaadita).
         * @param jobContractData
         * @returns {Promise<void>}
         */
        async postAccepted(jobContractData) {
            try {
                const response = await api.post(`/api/v3/contracts/${jobContractData}/accept`);
                if (response.status === 'ok') {
                    const data = response.data ?? {};
                    this.setContractState(data.jobContractState);
                    this.setContractDataType(data.jobContractDataType);
                    dispatch.notifications.addSuccess(_trans('Sopimus hyväksytty voimaan.', {}, 'jobContract'));
                }
                return response;
            } catch (e) {
                console.log(e);
            }
        },

        async fetchCollectiveAgreementConstantDetails(jobContract, rootState, key) {
            if (! key) {
                throw new Error('Key not provided');
            }

            try {
                const json = await api.get(`/api/contracts/v1/contracts/${jobContract}/collective-agreement-constant-details?tesConstantName=${key}`);
                this.setCollectiveAgreementConstantDetails({ key, value: json.data });
            } catch (e) {
                dispatch.notifications.addError(_trans('TES-tietoja ei voitu ladata', {}, 'extract'));
            }
        },

        /**
         * Hakee taulukkopalkan ja päivittää haluttuun palkka-alueeseen (initialSalary, costReimbursement...).
         * @param collectiveAgreement
         * @param values
         * @param date
         * @param types
         * @returns {Promise<void>}
         */
        async fetchTableSalary({ collectiveAgreement, values, date, types = [TYPE_SALARY] }) {
            try {
                const query = createQueryParams({ ...values, date, types });
                const response = await api.get(`/api/v1/tablesalaries/${collectiveAgreement}/salary?${query}`);
                if (response.status === 'ok') {
                    return response?.data ?? '';
                }
                if (response.error === ERROR_NOT_FOUND_FOR_DATE) {
                    return ERROR_NOT_FOUND_FOR_DATE;
                }
                return null;
            } catch (e) {
                console.log(e);
            }
        },

        /**
         * Hakee valitun TES:n rajoitteet (sopimustyypit ym.)
         * @param collectiveAgreementId
         * @returns {Promise<void>}
         */
        async fetchCollectiveAgreementRestrictions(collectiveAgreementId) {
            try {
                const response = await api.get(`/api/v1/contract/restrictions?tesId=${collectiveAgreementId}`);
                this.setCollectiveAgreementRestrictions(response);
            } catch (e) {
                console.error(e);
            }
        },

        /**
         * Valitaan TES ja asetetaan tietyt TES:stä riippuvat asiat talteen (takuupalkka ym.).
         */
        selectCollectiveAgreement({ collectiveAgreement, hasCollectiveAgreementExpired = false }, rootState) {
            this.setCollectiveAgreementName(collectiveAgreement.name);
            const hasCollectiveAgreementChanged = getCollectiveAgreementId(rootState?.contract) !== parseInt(collectiveAgreement?.collectiveAgreementId, 10);

            // Voidaanko TES-muuttujien oletusarvot resolvoida? Esim. päättyneiltä tesseiltä ei.
            // Lisäksi resolvoidaan vain jos TES-vaihtuu. Tämä siksi että jos muuttujissa on arvoja joita ei edes pysty
            // vaihtamaan vaihtuisi tämä pellin alla toiseksi.
            const collectiveAgreementVariables = (! hasCollectiveAgreementExpired && hasCollectiveAgreementChanged)
                ? resolveCollectiveAgreementDefaultValues(
                    (collectiveAgreement.variables ?? []),
                    rootState.contract.collectiveAgreement?.variables ?? {},
                )
                : getCollectiveAgreementVariables(rootState?.contract);

            this.setCollectiveAgreementVariables(collectiveAgreementVariables);
            this.setCollectiveAgreementAttributes(collectiveAgreement);
            this.setContractType(collectiveAgreement.jobContractType);
            this.setCollectiveAgreementType(collectiveAgreement.type);
            this.setCollectiveAgreementBaseType(collectiveAgreement.baseType);
        },

        /**
         * Tallentaa / päivittää sopparin.
         * @param values
         * @param jobContract
         * @param benefitDecisionId
         * @param rootState
         * @returns {Promise<void>}
         */
        async saveContract({ values, jobContract, benefitDecisionId }, rootState) {
            const jobContractId = parseInt(jobContract, 10);
            const presetData = getPresetData(rootState.contract);
            try {
                const url = isNaN(jobContractId)
                    ? '/api/v3/contracts'
                    : `/api/v3/contracts/${jobContractId}`;
                return await api.post(url, transformContractToBackend(
                    Object.assign({}, values, { benefitDecisionId }), presetData, rootState?.contract
                ));
            } catch (e) {
                console.error(e);
            }
        },

        /**
         * Työsuhteen päättäminen.
         */
        async terminateContract({ contract, values }) {
            try {
                return await api.post(`/api/contracts/v2/contracts/${contract}/termination`, values);
            } catch (e) {
                console.error(e);
            }
        },

        /**
         * Päättämisen peruminen.
         */
        async cancelContractTermination(contract) {
            try {
                const response = await api.del(`/api/contracts/v2/contracts/${contract}/termination`);
                if (response.status === 'ok') {
                    this.clearTerminationTime();
                }
                return response;
            } catch (e) {
                console.error(e);
            }
        },

        /**
         * Päätetyn sopimuksen avaaminen uudelleen.
         */
        async revertContractTermination(jobContract) {
            try {
                const response = await api.post(`/api/v3/contracts/${jobContract}/revert`);
                if (response.status === 'ok') {
                    this.clearTerminationTime();
                }
                return response;
            } catch (e) {
                console.error(e);
            }
        },

        /**
         * Nykyisen ehdotuksen peruminen.
         */
        async rejectProposalContract({ jobContractData, rejectReason }) {
            try {
                return await api.post(`/api/v3/contracts/${jobContractData}/reject`, { rejectReason });
            } catch (e) {
                console.error(e);
            }
        },

        /**
         * Poistaa luonnoksen.
         */
        async removeDraftContract(jobContractData) {
            try {
                return await api.del(`/api/v3/contracts/${jobContractData}/draft`);
            } catch (e) {
                return e;
            }
        },

        /**
         * Poistaa voimaan tulossa olevan version.
         */
        async removeContractVersion(jobContractData) {
            try {
                return await api.del(`/api/v3/contracts/version/${jobContractData}`);
            } catch (e) {
                return e;
            }
        },

        /**
         * Poistaa sopimuksen. Poistettavalla sopimuksella ei ole tuntimerkintöjä eikä muutakaan aktiviteettia vielä.
         */
        async removeContract(jobContract) {
            try {
                return await api.del(`/api/v3/contracts/${jobContract}`);
            } catch (e) {
                return e;
            }
        },

        async fetchLinkedContracts(contractId) {
            try {
                const json = await api.get(`/api/v3/contracts/${contractId}/linked-contracts`);
                if (json.status === 'ok') {
                    this.setLinkedContracts(json.data);
                    return json.data ?? [];
                }
            } catch (error) {
                console.log(error);
                return false;
            }
        },

        async patchLinkedContractsForceDisable(contractId) {
            try {
                const json = await api.patch(`/api/v3/contracts/${contractId}/linked-contracts/toggle-linking`);

                if (json.status === 'ok') {
                    dispatch.notifications.addSuccess(_trans('Tiedot tallennettu', {}, 'common'));
                }
                return json.data ?? [];
            } catch (error) {
                dispatch.notifications.addError(_trans('Tallennus epäonnistui', {}, 'common'));
                return false;
            }
        },

        async patchLinkedContractRequiresAttention(contractId) {
            try {
                const json = await api.patch(`/api/v3/contracts/${contractId}/linked-contracts/toggle-needs-attention`);

                if (json.status === 'ok') {
                    dispatch.notifications.addSuccess(_trans('Tiedot tallennettu', {}, 'common'));
                }
                return json.data ?? [];
            } catch (error) {
                dispatch.notifications.addError(_trans('Tallennus epäonnistui', {}, 'common'));
                return false;
            }
        },

        /**
         * Palauttaa mahdolliset aiemmat TT/hoidettava -parit
         * TODO: Poista tästä ja siirrä Customer.jsx:ään
         */
        async fetchExistingContractsBetweenCaredAndEmployee({ cared, employee }) {
            try {
                return await api.get(`/api/v3/contracts/existing-pairs?cared=${cared}&employee=${employee}`);
            } catch (e) {
                return e;
            }
        },
        /**
         * Hakee sopimukset jakosuhteet
         */
        async fetchDivisors(jobContractGroupId) {
            try {
                const response = await api.get(`/api/contracts/v3/job-contract-groups/${jobContractGroupId}/divisors`);
                this.setDivisors(response ?? []);
            } catch (e) {
                return e;
            }
        },
        async postDivisors({ jobContractGroupId, allDivisors, startDate }) {
            try {
                return await api.post(`/api/contracts/v3/job-contract-groups/${jobContractGroupId}/divisors`, {
                    divisors: allDivisors,
                    startDate,
                });
            } catch (e) {
                return e;
            }
        },
    }),
};
