import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import IconText from 'shared/components/IconText';

/**
 * Tehtävälista.
 * Käytetään esimerkiksi tilanteissa joissa halutaan viestiä käyttäjälle ne kohdat jotka on vielä tehtävä.
 */
export const TodoList = ({ list, modifierClass }) => (
    <ul className={classNames('o-list-bare', modifierClass)}>
        {list
            .filter((item) => item?.isVisible ?? true)
            .map((item, key) => {
                const isTaskDone = item?.isDone ?? false;
                return (
                    <li key={key} className="o-list-bare__item">
                        <IconText
                            mdIcon={isTaskDone ? 'check' : 'clear'}
                            iconSize="small"
                            iconModifierClass={isTaskDone ? 'u-color-positive' : 'u-color-negative'}
                            text={item?.task ?? '-'}
                        />
                    </li>
                );
            })}
    </ul>
);

TodoList.propTypes = {
    /**
     * Todo list
     */
    list: PropTypes.arrayOf(PropTypes.shape({
        isDone: PropTypes.bool,
        task: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        isVisible: PropTypes.bool,
    })).isRequired,
    /**
     * CSS modification class.
     */
    modifierClass: PropTypes.string,
};

TodoList.defaultProps = {
    modifierClass: '',
};
