import React, { Fragment, useEffect } from 'react';
import { Formik } from 'formik';
import { useParams } from 'react-router-dom';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { select } from '@rematch/select';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { InputGroup, MDSpinner, Page, SubmitButton } from 'shared/components';
import {
    CheckboxField,
    CheckboxListField,
    Form,
    FormField,
    IntegerField,
    RadioListField,
    TextField
} from 'shared/components/Formik';
import {
    documentsCategoryTypes,
    translateCategoryType,
} from 'shared/DocumentsCategories/constants/documentsCategoryTypes';
import validators, { errorMessages } from 'shared/validators';
import {
    AUXILIARY_CUSTODY_COST_REIMBURSEMENT,
    AUXILIARY_CUSTODY_TRAVELLING_COMPENSATION,
    FAMILY_CARE_ADDITIONAL_COMPENSATION,
    FAMILY_CARE_START_COMPENSATION,
    FAMILY_OR_RELATIVE_CARE_TRAVELLING_COMPENSATION, SUPPORTER_COST_REIMBURSEMENT, SUPPORTER_TRAVELLING_COMPENSATION,
} from 'TimeRegistration/pay-period-data/PayPeriodDataType';
import alert from 'shared/utils/alert';

export const CategoryForm = ({ listRoute }) => {
    const { categoryId } = useParams();
    const dispatch = useDispatch();
    const isNew = categoryId === 'new';
    const categories = useSelector(select.documents.getCategories);
    const isLoadingCategories = useSelector((state) => state.loading.effects.documents.fetchCategories);
    const isCommune = useSelector(select.userMetadata.isCommune);
    const isSaving = useSelector((state) => state.loading.effects.documents.postCategories);
    const visibilityDimensionTypeName = useSelector(select.dimensions.getVisibilityDimensionTypeName);
    const visibilityDimensions = useSelector(select.dimensions.getVisibilityDimensions);

    useEffect(() => {
        if (categories.length === 0) {
            dispatch.documents.fetchCategories();
        }
        dispatch.dimensions.fetchDimensionTypes({ isVisibilityDimension: true });
    }, []);

    if (isLoadingCategories) {
        return (
            <MDSpinner wrapped />
        );
    }

    const existingCategory = categories.find((c) => c.uuid === categoryId);

    const initialValues = isNew
        ? {
            name: '',
            categoryType: '',
            allowEmployees: false,
            allowEmployers: false,
            allowEmployeesUpload: false,
            allowEmployersUpload: false,
            defaultExpiryYear: '0',
            defaultExpiryMonth: '0',
            dimensions: [],
            useSmartScan: false,
            allowedPayPeriodDataTypes: [],
            isExpiryAfterContractTerminating: false,
        } : {
            ...existingCategory,
            allowedPayPeriodDataTypes: existingCategory?.allowedPayPeriodDataTypes.map((type) => type.toString()) ?? [],
            dimensions: existingCategory?.dimensions.map((dim) => dim.id.toString()) ?? [],

        };

    return (
        <Page
            heading={isNew ? _trans('Luo uusi kategoria', {}, 'documents') : _trans('Muokkaa kategoriaa', {}, 'documents')}
            goBack={{ to: listRoute }}
        >
            <Formik
                initialValues={initialValues}
                onSubmit={(model) => {
                    model = {
                        ...model,
                        allowedPayPeriodDataTypes: model.allowedPayPeriodDataTypes.map((type) => parseInt(type, 10)),
                    };

                    const handleResponse = (json) => {
                        if (json.status === 'ok') {
                            dispatch(push(listRoute));
                            dispatch.notifications.addSuccess(_trans('Kategoria tallennettu', {}, 'documents'));

                            return;
                        }

                        if (json.error === 'invalid_data') {
                            const errorMessages = json.data.map(({ property_path, message }) => ({
                                fieldLabel: property_path,
                                message,
                            }));

                            alert((
                                <Fragment>
                                    <p>
                                        {_trans('Kategorian tallentaminen ei onnistunut', {}, 'documents')}
                                    </p>
                                    <ul className="u-margin-bottom-none">
                                        {errorMessages.map(({ fieldLabel, message }, key) => (
                                            <li key={key}>
                                                <b>{fieldLabel}</b>
                                                <br/>{message}
                                            </li>
                                        ))}
                                    </ul>
                                </Fragment>
                            ), { alert: true });

                            return;
                        }

                        dispatch.notifications.addError(json.message);
                    };

                    if (isNew) {
                        dispatch.documents.postCategories(model).then(handleResponse);
                    } else {
                        dispatch.documents.updateCategory(categoryId, model).then(handleResponse);
                    }
                }}
                validationSchema={Yup.object().shape({
                    name: Yup.string().required(),
                    categoryType: Yup.string().required(),
                    defaultExpiryYear: Yup.string().required().test('isInteger', errorMessages.isInteger, validators.isInteger),
                    defaultExpiryMonth: Yup.string().required().test('isInteger', errorMessages.isInteger, validators.isInteger),
                    dimensions: visibilityDimensions.length > 0 ? Yup.array().min(1, 'Valitse vähintää yksi ' + visibilityDimensionTypeName) : Yup.array(),
                })}
            >
                {({ values }) => (
                    <Form hasDebugButton>
                        <FormField name="name" label={_trans('Kategorian nimi', {}, 'common')}>
                            <TextField />
                        </FormField>
                        <FormField name="categoryType" label={_trans('Kategorian tyyppi', {}, 'documents')}>
                            <RadioListField
                                disabled={! isNew}
                                options={Object.values(documentsCategoryTypes).filter((type) => type === documentsCategoryTypes.BENEFIT_DECISION ? isCommune : true)
                                    .map((type) => ({
                                        value: type,
                                        label: translateCategoryType(type, isCommune),
                                    }))}
                            />
                        </FormField>
                        {visibilityDimensionTypeName && (
                            <FormField name="dimensions" label={visibilityDimensionTypeName} helpText={_trans('Valitse millä valtuushallinta-ryhmillä kategoria on käytössä.', {}, 'documents')}>
                                <CheckboxListField
                                    name="dimensions"
                                    options={visibilityDimensions.map((dim) => ({ ...dim, label: `${dim.value} - ${dim.description ?? ''}`, value: dim.id.toString() }))}
                                />
                            </FormField>
                        )}
                        {
                            [documentsCategoryTypes.JOB_CONTRACT, documentsCategoryTypes.PAY_PERIOD].includes(values.categoryType) && (
                                <div>
                                    {isCommune
                                        ?
                                        <Fragment>
                                            <FormField name="allowEmployeesUpload">
                                                <CheckboxField label={_trans('Salli työntekijöiden tallentaa palveluun', {}, 'documents')} />
                                            </FormField>
                                            <FormField name="allowEmployees">
                                                <CheckboxField label={_trans('Salli työntekijöiden ladata palvelusta', {}, 'documents')} />
                                            </FormField>
                                            <FormField name="allowEmployersUpload">
                                                <CheckboxField label={_trans('Salli työnantajien tallentaa palveluun', {}, 'documents')} />
                                            </FormField>
                                            <FormField name="allowEmployers">
                                                <CheckboxField label={_trans('Salli työnantajien ladata palvelusta', {}, 'documents')} />
                                            </FormField>
                                        </Fragment>
                                        :
                                        <Fragment>
                                            <FormField name="allowEmployees">
                                                <CheckboxField label={_trans('Salli hoitajien tallentaa palveluun', {}, 'documents')} />
                                            </FormField>
                                            <FormField name="allowEmployeesUpload">
                                                <CheckboxField label={_trans('Salli hoitajien ladata palvelusta', {}, 'documents')} />
                                            </FormField>
                                        </Fragment>
                                    }
                                </div>
                            )
                        }
                        {values.categoryType === documentsCategoryTypes.JOB_CONTRACT && (
                            <FormField name="isExpiryAfterContractTerminating">
                                <CheckboxField label={_trans('Voimassaolo alkaa vasta sopimuksen päättämisen jälkeen', {}, 'documents')} />
                            </FormField>
                        )}
                        <FormField label={_trans('Oletus voimassaoloaika ', {}, 'documents')} >
                            <InputGroup>
                                <IntegerField size="2" name="defaultExpiryYear" />
                                <span className="u-margin-left-tiny u-margin-right-tiny"> {_trans('vuotta ', {}, 'documents')} </span>
                                <IntegerField size="2" name="defaultExpiryMonth" />
                                <span className="u-margin-left-tiny"> {_trans('kuukautta ', {}, 'documents')} </span>
                            </InputGroup>
                        </FormField>
                        {!isCommune && values.categoryType === documentsCategoryTypes.PAY_PERIOD && (
                            <Fragment>
                                <FormField
                                    name="useSmartScan"
                                    helpText={_trans('Ominaisuus käyttää alikäsittelijänä Visma Machine Learning, joka tulkitsee dokumenteista informaatiota.', {}, 'documents')}
                                >
                                    <CheckboxField
                                        name="useSmartScan"
                                        label={_trans('Yritä tulkita dokumentista summa sekä päivämäärä koneoppimisen avulla', {}, 'documents')}
                                    />
                                </FormField>
                                {values.useSmartScan && (
                                    <FormField name="allowedPayPeriodDataTypes" label={_trans('Valitse lisäkorvauslajit, joita kategorian dokumenteista voi luoda', {}, 'documents')}>
                                        <CheckboxListField
                                            options={[
                                                {
                                                    value: FAMILY_CARE_START_COMPENSATION.toString(),
                                                    label: _trans('Käynnistämiskorvaus', {}, 'documents'),
                                                },
                                                {
                                                    value: FAMILY_CARE_ADDITIONAL_COMPENSATION.toString(),
                                                    label: _trans('Erilliskorvaus', {}, 'documents'),
                                                },
                                                {
                                                    value: FAMILY_OR_RELATIVE_CARE_TRAVELLING_COMPENSATION.toString(),
                                                    label: _trans('Omais- tai perhehoidon Matkakustannusten korvaus', {}, 'documents'),
                                                },
                                                {
                                                    value: AUXILIARY_CUSTODY_TRAVELLING_COMPENSATION.toString(),
                                                    label: _trans('Oheishoitajan matkakustannustenkorvaus', {}, 'documents'),
                                                },
                                                {
                                                    value: SUPPORTER_TRAVELLING_COMPENSATION.toString(),
                                                    label: _trans('Tukihenkilön matkakustannustenkorvaus', {}, 'documents'),
                                                },
                                                {
                                                    value: SUPPORTER_COST_REIMBURSEMENT.toString(),
                                                    label: _trans('Tukihenkilön kulukorvaus', {}, 'documents'),
                                                },
                                                {
                                                    value: AUXILIARY_CUSTODY_COST_REIMBURSEMENT.toString(),
                                                    label: _trans('Oheishuoltajan kulukorvaus (ei toimeksiantosopimusta)', {}, 'documents'),
                                                }
                                            ]}
                                        />
                                    </FormField>
                                )}
                            </Fragment>
                        )}
                        <SubmitButton
                            mdIcon="save"
                            isPending={isSaving}
                        >
                            {_trans('Tallenna', {}, 'common')}
                        </SubmitButton>
                    </Form>
                )}
            </Formik>
        </Page>
    );
};

CategoryForm.propTypes = {
    listRoute: PropTypes.string.isRequired,
};
