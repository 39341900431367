import React from 'react';
import PropTypes from 'prop-types';
import RcTooltip from 'rc-tooltip';
import classNames from 'classnames';
import MDIcon from 'shared/components/MDIcon';

const Tooltip = ({
    children,
    placement,
    title,
    trigger,
    mouseEnterDelay,
    modifierClass,
}) => {
    if (React.Children.count(children) === 0 && modifierClass === '') {
        children = (
            <MDIcon
                icon="help_outline"
                size="small"
                //modifierClass="u-align-text-bottom u-margin-left-tiny"
                focusable
            />
        );
    }

    return (
        <RcTooltip
            placement={placement}
            trigger={trigger}
            overlay={title}
            transitionName="c-tooltip-slide"
            prefixCls="c-tooltip"
            mouseEnterDelay={mouseEnterDelay}
        >
            <span
                className={classNames({
                    'u-align-middle u-display-inline-block': modifierClass === '',
                    [modifierClass]: modifierClass !== '',
                })}>
                {children}
            </span>
        </RcTooltip>
    );
};

Tooltip.defaultProps = {
    children: undefined,
    placement: 'top',
    trigger: ['hover', 'focus'],
    visible: false,
    defaultVisible: false,
    mouseEnterDelay: 0.1,
    mouseLeaveDelay: 0.1,
    modifierClass: '',
};

Tooltip.propTypes = {
    children: PropTypes.node,
    placement: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    trigger: PropTypes.array,
    mouseEnterDelay: PropTypes.number,
    mouseLeaveDelay: PropTypes.number,

    /**
     * CSS:n muokkausluokka.
     */
    modifierClass: PropTypes.string,
};

export default Tooltip;
