import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { format, lastDayOfMonth } from 'date-fns';
import TableSalaryChoices from 'shared/ReForm/containers/CustomBlocks/ContractV3/Form/shared/TableSalaryChoices';
import { fieldNames } from 'ContractV3/constants/fieldNames';
import { hiddenFields } from 'ContractV3/constants/hiddenFields';
import { resolveChoiceChainOptions } from 'ContractV3/utils/resolveChoiceChainOptions';
import commonUtils from 'shared/utils/commonUtils';
import { ERROR_NOT_FOUND_FOR_DATE, TYPE_COST_REIMBURSEMENT } from 'shared/constants/tableSalary';
import { resolveArrayName } from 'shared/ReForm/utils/resolveArrayName';
import { FormField } from 'shared/components/Formik';
import { Value } from 'shared/components/Value';

/**
 * Peruskulukorvausten valinnat.
 * @param arrayName
 * @param name
 * @param originalName
 * @param attributes
 * @returns {JSX.Element}
 * @constructor
 */
const CostReimbursementAttributes = ({ arrayName, name, originalName, attributes }) => {
    const dispatch = useDispatch();
    const { hasFixedNoOp } = attributes;
    // Taulukkopalkan arvot (salary.salaries.NN.costReimbursementAttributes)
    const [field,,] = useField(name);

    // Onko taulukkoliksat käytössä
    const [isTableSalaryInUseField] = useField(resolveArrayName(arrayName, fieldNames.IS_TABLE_SALARY_IN_USE));
    const isTableSalaryInUse = isTableSalaryInUseField?.value ?? true;
    const hasCostReimbursementTableSalaries = useSelector(select.contract.hasCostReimbursementTableSalaries);
    const [,,isPendingCostReimbursementHelpers] = useField(resolveArrayName(arrayName, fieldNames.IS_PENDING_COST_REIMBURSEMENT));
    const isNewContract = useSelector(select.contract.isNewContract);

    // Hoidettava
    const [caredField] = useField(fieldNames.CARED);
    const cared = useSelector((state) => isNewContract
        ? select.customers.getCustomerById(state, parseInt(caredField.value, 10))
        : select.contract.getCustomer(state)
    );

    const metadata = useSelector(select.tableSalary.getCostReimbursementMetadata);

    // TES
    const [collectiveAgreementField] = useField(fieldNames.COLLECTIVE_AGREEMENT);
    const collectiveAgreement = collectiveAgreementField.value;
    const tableSalaryDate = useSelector(select.contract.getTableSalaryDate);
    const isAgeBasedCostReimbursementsMonthAdvance = useSelector(select.userMetadata.isAgeBasedCostReimbursementsMonthAdvance);
    // riippuen asetuksista lasketaan ikä kuun vikan tai ekan päivän mukaan, jos nostetaan kuun alussa niin lasketaan ikä kuun vikan päivän mukaan
    const resolvedDate = isAgeBasedCostReimbursementsMonthAdvance ? format(lastDayOfMonth(new Date(tableSalaryDate)), 'yyyy-MM-dd') : tableSalaryDate;
    const caredAge = commonUtils.resolveUserAgeInYears(cared, resolvedDate);

    // Salaarityyppi
    const [salaryTypeField] = useField(fieldNames.SALARY_TYPE);
    const salaryType = salaryTypeField.value;

    // Peruskulukorvaus
    const [,,costReimbursementHelpers] = useField(resolveArrayName(arrayName, fieldNames.COST_REIMBURSEMENT));
    const [,,costReimbursementLabelHelpers] = useField(resolveArrayName(arrayName, hiddenFields.COST_REIMBURSEMENT_LABEL));

    // Päivitetään valinnat vain jos jokin riippuvuus muuttuu
    const options = useMemo(() => {
        const values = field.value || {};
        return (
            metadata
                .map((option) => ({
                    ...option,
                    label: _transObj(option.label),
                    options: resolveChoiceChainOptions(option, { values, salaryType, caredAge, tableSalaryDate })
                        // Jos asetettu ettei koskaan makseta valitaan Ei makseta suoraan ettei asiakkaat ränkkää
                        .filter((option) => hasFixedNoOp
                            ? option.value === 'no-op'
                            : true)
                }))
        );
    }, [metadata, field.value, salaryType, caredAge, hasFixedNoOp, tableSalaryDate]);

    if (isTableSalaryInUse && ! hasCostReimbursementTableSalaries) {
        return (
            <FormField isContentFormField={false} name={name}>
                <Value ariaDescribedBy={name}>
                    {_trans('Ei makseta.', {}, 'common')}
                </Value>
            </FormField>
        );
    }

    if (! isTableSalaryInUse || ! hasCostReimbursementTableSalaries) return null;

    return (
        <TableSalaryChoices
            arrayName={arrayName}
            options={options}
            name={name}
            originalName={originalName}
            onLastOptionChange={(option) => {
                const salaryLabelSelect = metadata.find((data) => data.useSalaryLabel ?? false);
                if (salaryLabelSelect) {
                    costReimbursementLabelHelpers.setValue(`${_trans('Peruskulukorvaus', {}, 'jobContract')}: ${option.label}`);
                }
            }}
            onChange={(values) => {
                const hasNoop = Object.values(values.values).includes('no-op');
                if (hasNoop) {
                    isPendingCostReimbursementHelpers.setValue(false);
                    return costReimbursementHelpers.setValue('0');
                }

                // Haetaan taulukkopalkat
                dispatch.contract.fetchTableSalary({
                    collectiveAgreement,
                    values: values.values,
                    date: tableSalaryDate,
                    types: [
                        TYPE_COST_REIMBURSEMENT,
                    ]
                }).then((salary) => {
                    if (salary === ERROR_NOT_FOUND_FOR_DATE) {
                        isPendingCostReimbursementHelpers.setValue(true);
                        costReimbursementHelpers.setValue('0.00');

                        return;
                    }
                    costReimbursementHelpers.setValue(salary);
                    isPendingCostReimbursementHelpers.setValue(false);
                });
            }}
        />
    );
};

CostReimbursementAttributes.propTypes = {
    arrayName: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    originalName: PropTypes.string.isRequired,
    attributes: PropTypes.shape({
        hasFixedNoOp: PropTypes.bool,
    }).isRequired,
};

export default CostReimbursementAttributes;
