import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { SelectField } from 'shared/components/Formik/SelectField';
import { RadioListField } from 'shared/components/Formik/RadioListField';
import { AutoCompleteField } from 'shared/components/Formik/AutoCompleteField';
import { HiddenField } from 'shared/components/Formik/HiddenField';
import { Value } from 'shared/components/Value';

/**
 * Radio, Select or AutoComplete, based on number of options.
 * @param children
 * @param name
 * @param placeholder
 * @param options
 * @param valueKey
 * @param labelKey
 * @param isFullWidth
 * @returns {JSX.Element}
 * @constructor
 */
export const ChoiceField = ({ name, placeholder, options, valueKey, labelKey, isFullWidth, ...rest }) => {
    // Harvinaisempi case
    const hasOptGroup = options.some((option) => option.hasOwnProperty('optGroup'));

    if (options.length >= 100) {
        return (
            <AutoCompleteField
                name={name}
                items={options}
                labelKey={labelKey}
                valueKey={valueKey}
                placeholder={placeholder}
            />
        );
    }
    // If there are more than 5 options use dropdown
    if (options.length > 5 || hasOptGroup) {
        return (
            <SelectField
                {...rest}
                aria-labelledby={name}
                placeholder={placeholder}
                name={name}
                options={options}
                valueKey={valueKey}
                labelKey={labelKey}
                isFullWidth={isFullWidth}
            />
        );
    }

    if (options.length === 1) {
        return (
            <Fragment>
                <Value ariaDescribedBy={name}>
                    {options[0][labelKey]}
                </Value>
                <HiddenField name={name} value={options[0][valueKey]} />
            </Fragment>
        );
    }

    return (
        <RadioListField
            {...rest}
            name={name}
            options={options}
            valueKey={valueKey}
            labelKey={labelKey}
        />
    );
};

ChoiceField.propTypes = {
    name: PropTypes.string,
    placeholder: PropTypes.string,
    /**
     * List of options for checkboxes.
     */
    options: PropTypes.array.isRequired,
    /**
     * Key to be used for value.
     */
    valueKey: PropTypes.string,
    /**
     * Key to be used for label.
     */
    labelKey: PropTypes.string,
    /**
     * Is the dropdown full width.
     */
    isFullWidth: PropTypes.bool,
};

ChoiceField.defaultProps = {
    name: undefined,
    placeholder: _trans('Valitse', {}, 'common'),
    valueKey: 'value',
    labelKey: 'label',
    required: false,
    isFullWidth: false,
};
