import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useField } from 'formik';
import Placeholder from 'shared/components/Placeholder';
import { Value } from 'shared/components/Value';
import { FormField } from 'shared/components/Formik';
import { fieldNames } from 'ContractV3/constants/fieldNames';
import { resolveSalaryPostFix } from 'ContractV3/utils/salaries/resolveSalaryPostFix';
import { resolveArrayName } from 'shared/ReForm/utils/resolveArrayName';
import { salaryTypes } from 'shared/constants/index';

/**
 * Kustannusten korvauksen määrä.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */

export const CostReimbursementAmount = ({ label, name, arrayName }) => {
    const isFetchingTableSalary = useSelector((state) => state.loading.effects.tableSalary.fetchTableSalary);

    // Palkkatyyppi
    const [salaryField] = useField(fieldNames.SALARY_TYPE);
    const salaryType = parseInt(salaryField.value, 10);

    // Onko taulukkoliksat käytössä
    const [isTableSalaryInUseField] = useField(resolveArrayName(arrayName, fieldNames.IS_TABLE_SALARY_IN_USE));
    const isTableSalaryInUse = isTableSalaryInUseField?.value ?? true;

    const [isPendingCostReimbursementField] = useField(resolveArrayName(arrayName, fieldNames.IS_PENDING_COST_REIMBURSEMENT));
    const [field] = useField(name);
    const amount = field.value;

    if (! amount || ! isTableSalaryInUse) return null;

    return (
        <FormField
            name={name}
            label={label}
            isContentFormField={false}
        >
            <Placeholder isPending={isFetchingTableSalary}>
                <Value ariaDescribedBy={name}>
                    {isPendingCostReimbursementField.value
                        ? _trans('Odottaa indeksikorotuksia', {}, 'jobContract')
                        : resolveSalaryPostFix(salaryType === salaryTypes.PER_HOUR ? salaryTypes.PER_DAY : salaryType, _currency(amount))}
                </Value>
            </Placeholder>
        </FormField>
    );
};

CostReimbursementAmount.propTypes = {
    /**
     * Taulukkopalkkojen kohde johon määrä päivitetään, esim. costReimbursementAttributes.
     */
    name: PropTypes.string.isRequired,
    arrayName: PropTypes.string.isRequired,
    label: PropTypes.string,
};

CostReimbursementAmount.defaultProps = {
    label: null,
};
